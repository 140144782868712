
























import Vue from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import WelcomeBack from "@/components/WelcomeBack.vue";

export default Vue.extend({
  name: "Home",
  components: {
    HelloWorld,
    WelcomeBack,
  },
  props: {
    isLoggedIn: Boolean,
    isInGuild: Boolean,
  },
});

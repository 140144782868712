





















































import Vue from "vue";

export default Vue.extend({
  name: "WelcomeBack",
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isOrganizer() {
      return this.$store.getters.isOrganizer;
    },
  },
});


































































import Vue from "vue";
import QRCode from "@/components/QRCode.vue";
import axios from "axios";
import { User } from "./store";

interface data {
  isQRCodeModalActive: boolean;
  code: string;
  qrRefreshTimer: ReturnType<typeof setTimeout> | undefined;
  qrExpires: number;
}

interface APIEvent {
  id: number;
  name: string;
  isOrganizer: boolean;
}

export default Vue.extend({
  name: "App",
  components: { QRCode },
  data() {
    const d: data = {
      isQRCodeModalActive: false,
      code: "",
      qrRefreshTimer: undefined,
      qrExpires: 0,
    };
    return d;
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isOrganizer() {
      return this.$store.getters.isOrganizer;
    },
    isInGuild() {
      return this.$store.getters.isInGuild;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    isQRCodeModalActive: function (enabled) {
      if (!enabled && this.qrRefreshTimer) {
        clearTimeout(this.qrRefreshTimer);
        this.qrRefreshTimer = undefined;
      }
    },
  },
  methods: {
    showCheckin() {
      this.$router.push({ name: "Checkin" });
    },
    showQR() {
      // Hide the code if it's expired
      if (this.qrExpires < Math.round(new Date().getTime() / 1000)) {
        this.code = "";
      }
      this.isQRCodeModalActive = true;
      axios
        .get("https://api.theseattleclubhouse.com/qr", {
          withCredentials: true,
        })
        .then((res) => {
          this.code = res.data.qr_text;
          this.qrExpires = res.data.expires;
          const expiresInSec =
            res.data.expires - Math.round(new Date().getTime() / 1000);
          if (expiresInSec > 0) {
            // Refresh just 10 seconds before it actually expires
            const nextRefresh = Math.max(expiresInSec - 10, 10) * 1000;
            this.qrRefreshTimer = setTimeout(this.showQR, nextRefresh);
          }
        })
        .catch((err) => {
          console.error(err);
          this.login();
        });
    },
    login() {
      const isDev = process.env.NODE_ENV === "development" ? "%3Fdev%3D1" : "";
      window.location.href =
        `https://discord.com/api/oauth2/authorize?` +
        `client_id=665008025119096901&` +
        `redirect_uri=https%3A%2F%2Fapi.theseattleclubhouse.com%2Fdiscord${isDev}&` +
        `response_type=code&` +
        `scope=identify`;
    },
  },
  mounted: function () {
    axios
      .get("https://api.theseattleclubhouse.com/", {
        withCredentials: true,
      })
      .then((resp) => {
        const munch = (resp.data.events as APIEvent[]).find((e) => e.id === 0);
        let isOrganizer = false;
        if (munch) {
          isOrganizer = munch.isOrganizer;
        }
        this.$store.commit("setUser", {
          discordInfo: resp.data.discord,
          expires: resp.data.expires,
          isOrganizer: isOrganizer,
        } as User);
      })
      .catch(() => {
        this.$store.commit("setUser", {});
      });
  },
});

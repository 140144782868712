
















import Vue from "vue";

export default Vue.extend({
  name: "QRScanMessage",
  props: {
    username: String,
    committed: Boolean,
    error: String,
  },
});

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export interface DiscordGuild {
  id: string;
  name: string;
}

export interface DiscordUser {
  id: string;
  username: string;
  nick: string;
  discriminator: string;
  guilds: DiscordGuild[];
}

export interface User {
  discordInfo: DiscordUser;
  expires: number;
  isOrganizer: boolean;
}

export interface State {
  user: User;
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    user: undefined,
    accepted18: window.localStorage.getItem("is18"),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    accept18(state) {
      window.localStorage.setItem("is18", "true");
      state.accepted18 = window.localStorage.getItem("is18");
    },
  },
  actions: {
    // FIXME: Create getUser here
    // https://stackoverflow.com/questions/41835716/how-do-i-set-initial-state-in-vuex-2
  },
  modules: {},
  getters: {
    isLoggedIn: (state) => {
      const user = state.user as unknown as User;
      console.log("isLoggedIn, user", user);
      if (user === undefined) {
        return undefined;
      }
      const expiry = user.expires;
      if (expiry === undefined) {
        return false;
      }
      return expiry && expiry < new Date().getTime();
    },
    isInGuild: (state) => {
      const user = state.user as unknown as User;
      return user?.discordInfo?.guilds?.some(
        (guild) => guild.id === "620712570927448094"
      );
    },
    isOrganizer: (state) => {
      const user = state.user as unknown as User;
      return user?.isOrganizer;
    },
    user: (state) => {
      return state.user;
    },
    accepted18: (state) => {
      return state.accepted18;
    },
  },
});

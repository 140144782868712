import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Checkin from "@/views/Checkin.vue";
import AgeGate from "@/views/AgeGate.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/agegate",
    name: "AgeGate",
    component: AgeGate,
  },
  {
    path: "/checkin",
    name: "Checkin",
    component: Checkin,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some((record) => record.meta.requiresLogin)) {
  // }
  if (to.name !== "AgeGate" && !store.getters.accepted18) {
    next({
      name: "AgeGate",
      query: {
        next: to.fullPath,
      },
    });
    return;
  }
  next();
});

export default router;

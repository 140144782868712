

































































































































































import Vue from "vue";

export default Vue.extend({
  name: "HelloWorld",
  data() {
    return {
      activeStep: 0,
      nextText: "Nice to meet you!",
      backText: "Let's go back",
      showNext: true,
      showBack: false,
      enableNext: true,
    };
  },
  methods: {
    stepChanged: function (step: string | number) {
      this.showNext = true;
      switch (step) {
        case 0:
          this.nextText = "Nice to meet you!";
          this.enableNext = true;
          this.backText = "";
          this.showBack = false;
          break;
        case 1:
          this.nextText = "";
          this.showNext = false;
          this.enableNext = false;
          this.backText = "Who are you again?";
          this.showBack = true;
          break;
      }
      console.log(step);
      // Scroll to top
      window.scrollTo(0, 0);
    },
  },
});


















import Vue from "vue";
import QRCode from "qrcode";

export default Vue.extend({
  name: "QRCode",
  props: {
    code: String,
  },
  methods: {
    updateQR(value: string) {
      if (value) {
        QRCode.toCanvas(this.$refs["qr-canvas"], value);
      }
    },
  },
  watch: {
    code: function (value) {
      this.updateQR(value);
    },
  },
  mounted() {
    this.updateQR(this.code);
  },
});

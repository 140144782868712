






























import axios from "axios";
import Vue from "vue";
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js";
import QRScanMessage from "@/components/QRScanMessage.vue";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

interface qaScanMessage {
  username: string;
  committed: boolean;
  error?: string;
}

interface qaStyle {
  width: string;
  height: string;
  top: string;
  left: string;
}

interface data {
  qrScanner?: QrScanner;
  lastScannedCode: string;
  cameraState: "UNKNOWN" | "CAMERA" | "NO_CAMERA";
  cameraIds: string[];
  sawQRCode: boolean;
  qrScanMessages: qaScanMessage[];
  qrOverlayStyle?: qaStyle;
}

export default Vue.extend({
  components: { QRScanMessage },
  name: "Checkin",
  props: {},
  data() {
    return {
      qrScanner: undefined,
      lastScannedCode: "",
      cameraState: "UNKNOWN",
      cameraIds: [],
      sawQRCode: false,
      qrScanMessages: [],
      qrOverlayStyle: undefined,
    } as data;
  },
  methods: {
    onQrDecode(result: string) {
      if (!this.sawQRCode && this.lastScannedCode !== result) {
        this.lastScannedCode = result;
        console.log("QrScanner found", result);

        const jwtParts = result.split(".");
        if (jwtParts.length !== 3) {
          console.error("Not a JWT");
          return;
        }

        try {
          // Maybe use a library??
          const payload = JSON.parse(atob(jwtParts[1]));
          if (payload.d_un === undefined || payload.d_dn === undefined) {
            console.error("Invalid JWT payload");
            return;
          }

          // Simple vibrate for feedback
          window.navigator.vibrate([100]);
          this.registerAttendee(`${payload.d_un}#${payload.d_dn}`, result);
        } catch (error) {
          console.error(error);
        }
      }
    },
    registerAttendee(username: string, token: string) {
      this.sawQRCode = true;

      this.setQrScanMessage({
        username: username,
        committed: false,
        error: undefined,
      });

      axios
        .post(
          "https://api.theseattleclubhouse.com/qr",
          {
            qr_text: token,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          this.setQrScanMessage({
            username: username,
            committed: true,
            error: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.setQrScanMessage({
              username: username,
              committed: false,
              error: err.response.data.message,
            });
          } else {
            console.error(err);
            this.setQrScanMessage({
              username: username,
              committed: false,
              error: err.message,
            });
          }
        })
        .finally(() => {
          this.sawQRCode = false;
        });
    },
    setQrScanMessage(msg: qaScanMessage) {
      let found = false;
      this.qrScanMessages.forEach((value, i) => {
        if (value.username === msg.username) {
          // Setting the array item through normal means won't trigger Vue updates,
          // so we tell Vue to do the replacement itself, and trigger the correct
          // things to re-render our view
          this.$set(this.qrScanMessages, i, msg);
          found = true;
        }
      });

      if (!found) {
        this.qrScanMessages.push(msg);
      }
    },
    handleResize() {
      const qrOverlay = this.$refs["camera"] as HTMLElement;
      if (!qrOverlay) {
        return;
      }
      const smallestDimension = Math.min(
        qrOverlay.offsetWidth,
        qrOverlay.offsetHeight
      );
      const sourceRectSize = Math.round((2 / 3) * smallestDimension);
      this.qrOverlayStyle = {
        width: `${sourceRectSize}px`,
        height: `${sourceRectSize}px`,
        top: `${(qrOverlay.offsetHeight - sourceRectSize) / 2}px`,
        left: `${(qrOverlay.offsetWidth - sourceRectSize) / 2}px`,
      };
    },
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    QrScanner.hasCamera().then((value) => {
      this.cameraState = value ? "CAMERA" : "NO_CAMERA";
      if (value) {
        this.qrScanner = new QrScanner(
          this.$refs["camera"] as HTMLVideoElement,
          this.onQrDecode
        );

        this.qrScanner.start();
        this.$nextTick(() => {
          this.handleResize();
          // FIXME: The height doesn't stabalize here, so
          // just in case let's call it one more time
          setTimeout(() => {
            this.handleResize();
          }, 500);
        });
      }
    });
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    if (this.qrScanner) {
      this.qrScanner.destroy();
      this.qrScanner = undefined;
    }
  },
});

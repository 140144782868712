
































import Vue from "vue";

export default Vue.extend({
  name: "AgeGate",
  methods: {
    enter() {
      this.$store.commit("accept18");
      this.$router.replace(this.$router.currentRoute.query["next"] as string);
    },
  },
});
